export const CONTRACT_ADDRESS_UniswapV2Router02 =
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
//mainnet
// export const UNISWAP_CONTRACT_ADDRESS_MAINNET = // not main net
   //"0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

// rinkeby
 export const UNISWAP_CONTRACT_ADDRESS_RINKEBY =
  "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";

//************************************************************* */
// export const URL_INFURA_RINKEBY =
//   "https://rinkeby.infura.io/v3/3677788ecbb042f3b941ddd26dc8fc08";

export const URL_INFURA_MAINNET =
  "https://mainnet.infura.io/v3/d26d5f4154164d209e83e3ca30be634f";

export const URL_INFURA_RINKEBY =
  "https://rinkeby.infura.io/v3/d26d5f4154164d209e83e3ca30be634f";

export const URL_INFURA_OPTIMISM_MAINNET =
  "https://optimism-mainnet.infura.io/v3/d26d5f4154164d209e83e3ca30be634f";

//export const URL_INFURA_OPTIMISM_KOVAN =
  //"https://optimism-kovan.infura.io/v3/d26d5f4154164d209e83e3ca30be634f";

export const URL_INFURA_POLYGON_MAINNET =
  "https://polygon-mainnet.infura.io/v3/d26d5f4154164d209e83e3ca30be634f";

//************************************************************* */
// export const UNISWAP_V2_PHNX_ETH_PAIR_ADDRESS_RINKEBY =
// "0xff8ae8805552c813d75ad6ff456dbc417bd12be6";

export const UNISWAP_V2_PHNX_ETH_PAIR_ADDRESS_MAINNET =
  "0x3e86Fdb06Cb7Ca09aD053e2443E010273aA98613";

export const UNISWAP_V2_PHNX_ETH_PAIR_ADDRESS_RINKEBY =
  "0x3e86Fdb06Cb7Ca09aD053e2443E010273aA98613";

//************************************************************* */
// export const PHNX_RINKEBY_TOKEN_ADDRESS =
// "0xfe1b6abc39e46cec54d275efb4b29b33be176c2a";

// export const PHNX_MAINNET_TOKEN_ADDRESS =
// "0x38A2fDc11f526Ddd5a607C1F251C065f40fBF2f7";

export const PHNX_RINKEBY_TOKEN_ADDRESS =
"0x4F3d67C55c5665942cf598bb883d39c43e38C6cB";

//************************************************************* */
// export const PHNX_LP_STAKING_CONTRACT_ADDRESS_RINKEBY =
//   "0x9cf7C9B4433ddf629C9b729C462558604Ad3c3cC";

export const PHNX_LP_STAKING_CONTRACT_ADDRESS_MAINNET = "0xC505CFd4Bde999EA3674f4a535A354c243289817";

export const PHNX_LP_STAKING_CONTRACT_ADDRESS_RINKEBY = "0xC505CFd4Bde999EA3674f4a535A354c243289817";

//************************************************************* */
//export const TX_LINK_RINKEBY = "https://rinkeby.etherscan.io/tx/";
export const TX_LINK_MAINNET = "https://etherscan.io/tx/";

export const TX_LINK_RINKEBY = "https://etherscan.io/tx/";

//************************************************************* */
export const ETHERSCAN_ACCOUNT_LINK_RINKBY = "https://rinkeby.etherscan.io/address/";

export const ETHERSCAN_ACCOUNT_LINK_MAINNET = "https://etherscan.io/address/";

//export const ETHERSCAN_ACCOUNT_LINK_RINKBY = "https://etherscan.io/address/";